/* You can add global styles to this file, and also import other style files */

.text-center{
    text-align: center;
}

.top-10{
    margin-top: 10px;
}

.color-blue{
    color: #3f51b5 !important;
    font-weight: 400;
}

.icon-space{
    margin-right: 10px;
}

.global-orange{
    color: #fd7e14 !important;
}

.center{
    text-align: center;
}

.width-55{
    width: 55px !important;
}

.mat-mini-fab.mat-primary {
    background-color: #fd7e14 !important;
}

.materialTableHeader .header-buttons-left input.search-field {
    width: 500px !important;
}

.color-green{
    background-color: #198754 !important;
}

.softech-color-green{
    color: #198754 !important;
}

.custom-modal-footer {
    padding: 10px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

.gray{
    background-color:gray !important;
}

.softech-orange {
    color: #fd7e14 !important;
}

.softech-vert-bg {
    background-color: green !important;
}

.softech-orange-bg {
    background-color: #fd7e14 !important;
}

.swal2-title{
    font-size: 17px !important;
}

.swal2-container {
    z-index: 10000;
  }

.border-color-card{
   border-color:#c2bfbd !important;
}

.margin-top-moins20{
    margin-top: -20px;
}

.margin-rigth-60 {
    margin-right: 60px;
}

.table-responsive tr:hover{
    background-color: #96c1ad8c;
    cursor: pointer;
}

.header-class-top {
    width: 100%;
    height: 265px;
    position: relative;
    border-top: solid 5px #198754;
}

.header-class {
    background-color: white;
    width: 100%;
    height: 100%;
    z-index: 50;
}
.welcome-bar {
    width: 100%;
    background-color: #EC4E00;
    border-top: solid 5px #198754;
    height: 15px;
    position: relative;
}

.info-bar {
    height: 35px;
    margin-top: 13px;
}

.info-title-block {
    height: 100%;
    background-color: #fd7e14;
}
.defilement-title-block {
    background-color: #dddcdc;
    height: 34px;
}

.flash-infos {
    color: white;
    font-weight: 300 !important;
    padding: 10px 0px 0px 10px;
}

.marque-text {
    padding-top: 7px;
    font-weight: 400;
}

.welcome-message {
    color: white;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    padding-top: 16px;
}

.logo-info {
    position: relative;
    z-index: 99;
    padding-top: 20px;
    height: 100%;
}

.row-wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}

.header-message {
    text-align: center;
    vertical-align: middle;
    padding-top: 120px;
    color: rgba(0,64,0,0.95);;
}

.align-header{
    vertical-align: middle !important;
}

.main-view {
    height: 280px;
    margin-top: 10px;

}

.main-footer {
    height: 70px; 
    border: solid 1px gray;
    margin-top: 30px;
    background-color: rgba(0,64,0,0.95);
}

.main-copyright {
    height:100px; 
    padding-top: 45px;

}

.main-text {
    padding: 13px 30px 14px 40px;
    font-size: 18px;
}

.text-select {
    font-size: 18px;
    padding-top: 20px;
}
.general-bg {
    background-color: #efe2d8a1;
}